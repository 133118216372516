import gql from 'graphql-tag'

const ReportesModel = {
    reporteVentas: (client, {periodo, validar, fisico}, recursos) => client.query({
        query: gql`query reporteVentas($periodo: String!, $validar: Boolean!, $fisico: Boolean) {
            reporteVentas(periodo: $periodo, validar: $validar, fisico: $fisico) {
                ${recursos}
            }
        }`,
        variables: {periodo, validar, fisico},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    downloadExcel: (client, {periodo, isVenta, excel_pdf}, recursos) => client.query({
        query: gql`query descargarExcel($periodo: String, $isVenta: Boolean, $excel_pdf: String){
            descargarExcel(periodo: $periodo, isVenta: $isVenta, excel_pdf: $excel_pdf){
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        variables: {periodo, isVenta, excel_pdf},
        fetchPolicy: 'no-cache'
    }),
    reporteCompras: (client, {periodo, validar}, recursos) => client.query({
        query: gql`query reporteCompras($periodo: String!, $validar: Boolean!){
            reporteCompras(periodo: $periodo, validar: $validar){
                ${recursos}
            }
        }`,
        variables: {periodo, validar},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    getConstancia: (client, {periodo, type}, recursos) => client.query({
        query: gql`query constanciaLeCvd($periodo: String!, $type: String!){
            constanciaLeCvd(periodo: $periodo, type: $type){
                ${recursos}
            }
        }`,
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    LEVentas: (client, periodo, recursos) => client.query({
        query: gql`query LEVentas($periodo: String){
            LEVentas(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {periodo},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    descargarTXT: (client, {periodo, type, O}, recursos) => client.query({
        query: gql`query txtDescargar($periodo: String!, $type: String!, $O: String) {
            txtDescargar(periodo: $periodo, type: $type, O: $O) {
                ${recursos}
            }
        }`,
        variables: {periodo, type, O: O ? '2' : '1'},
        fetchPolicy: 'no-cache'
    }),
    LECompras: (client, {periodo}, recursos) => client.query({
        query: gql`query LECompras($periodo: String!){
            LECompras(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    Kardex13: (client, {periodo, isCosto, conNotas}, recursos) => client.query({
        query: gql`query kardex13($periodo: String , $isCosto: Boolean, $conNotas: Boolean){
            kardex13(periodo: $periodo, isCosto: $isCosto,conNotas: $conNotas){
                ${recursos}
            }
        }`,
        variables: {periodo, isCosto, conNotas},
        fetchPolicy: 'no-cache'
    }),
    getLeDiario: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLeDiario($periodo: String!, $type: String!){
                lEdiario(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        context: {timeout: 960000},
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    getTxtLeCvd: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getTxtLeCvd($periodo: String!, $type: String!) {
            txtLeCvd(periodo: $periodo, type: $type) {
                ${recursos}
            }
        }`,
        variables: {periodo, type},
        fetchPolicy: "no-cache"
    }),
    getConstanciaLeCvd: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getConstanciaLeCvd($periodo: String!, $type: String!) {
            constanciaLeCvd(periodo: $periodo, type: $type) {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        variables: {periodo, type},
        fetchPolicy: "no-cache"
    }),
    excelPCGE: (client, recursos) => client.query({
        query: gql`{
            excelPCGE{
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    excelCuentasPorCobrar: (client, {criterio, param, libro, estado}, recursos) => client.query({
        query: gql`query excelCuentasPorCobrar($criterio: String!, $param: String!, $libro: String!, $estado: String!){
            excelCuentasPorCobrar(criterio: $criterio, param: $param, libro: $libro, estado: $estado){
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {criterio, param, libro, estado},
        fetchPolicy: "no-cache"
    }),
    excelByCxPx: (client, {
        libro, estado, sDate, sDateHasta, proveedor, sCuo, moneda, periodo, isQueryPeriodo
    }, recursos) => client.query({
        query: gql`query excelByCxPx($libro: String!, $estado: String!, $sDate: String, $sDateHasta: String, $proveedor: String, $sCuo: String, $moneda: String!, $periodo: String) {
            excelByCxPx(libro: $libro, estado: $estado, sDate: $sDate, sDateHasta: $sDateHasta, proveedor: $proveedor, sCuo: $sCuo, moneda: $moneda, periodo: $periodo) {
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {
            libro, estado, sDate: !isQueryPeriodo ? sDate : null, sDateHasta: !isQueryPeriodo ? sDateHasta : null,
            proveedor, sCuo, moneda, periodo: isQueryPeriodo ? periodo : null
        },
        fetchPolicy: "no-cache"
    }),
    excelByAfectacion: (client, {
        libro, estado, sDate,
        sDateHasta,
        proveedor,
        sCuo,
        moneda,
        periodo,
        isQueryPeriodo
    }, recursos) => client.query({
        query: gql`query excelByAfectacion($libro: String!, $estado: String!, $sDate: String, $sDateHasta: String, $proveedor: String, $sCuo: String, $moneda: String!, $periodo: String) {
            excelByAfectacion(libro: $libro, estado: $estado, sDate: $sDate, sDateHasta: $sDateHasta, proveedor: $proveedor, sCuo: $sCuo, moneda: $moneda, periodo: $periodo) {
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {
            libro, estado, sDate: !isQueryPeriodo ? sDate : null, sDateHasta: !isQueryPeriodo ? sDateHasta : null,
            proveedor, sCuo, moneda, periodo: isQueryPeriodo ? periodo : null
        },
        fetchPolicy: "no-cache"
    }),
    excelVentasCompras: (client, {libro, fecha_desde, fecha_hasta}, recursos) => client.query({
        query: gql`query excelVentasCompras($libro: String!, $fecha_desde: String, $fecha_hasta: String){
                excelVentasCompras(libro: $libro, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta) {
                      ${recursos}
                  }
              }`,
        context: {timeout: 480000},
        variables: {libro, fecha_desde, fecha_hasta},
        fetchPolicy: 'no-cache'
    }),
    excelCuentasFinancieras: (client, recursos) => client.query({
        query: gql`{
              excelCuentasFinancieras {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    excelKardex13: (client, {isUnidades, periodo, conNotas, excelPdf}, recursos) => client.query({
        query: gql`query excelkardex13($periodo: String, $isUnidades: Boolean, $conNotas: Boolean, $excelPdf: String){
            excelkardex13(periodo: $periodo, isUnidades: $isUnidades, conNotas: $conNotas, excelPdf: $excelPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {periodo, isUnidades, conNotas, excelPdf},
        fetchPolicy: 'no-cache'
    }),
    excelStock: (client, {periodo, conNotas, downloadPdf}, recursos) => client.query({
        query: gql`query excelStock($periodo: String, $conNotas: Boolean , $downloadPdf: Boolean ){
            excelStock(periodo: $periodo, conNotas: $conNotas, downloadPdf: $downloadPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {periodo, conNotas, downloadPdf},
        fetchPolicy: 'no-cache'
    }),
    excelCostos: (client, {periodo, isMargenUtilidad, conNotas, isCosto, downloadPdf}, recursos) => client.query({
        query: gql`query excelCostos($periodo: String, $isMargenUtilidad: Boolean, $conNotas: Boolean, $isCosto: Boolean, $downloadPdf: Boolean){
            excelCostos(periodo: $periodo, isMargenUtilidad: $isMargenUtilidad, conNotas: $conNotas, isCosto: $isCosto, downloadPdf: $downloadPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {periodo, isMargenUtilidad, conNotas, isCosto, downloadPdf},
        fetchPolicy: 'no-cache'
    }),
    reporteVentasDetallado: (client, {periodo, check}, recursos) => client.query({
        query: gql`query reporteVentasDetallado($periodo: String, $check: String){
            reporteVentasDetallado(periodo: $periodo, check: $check){
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {periodo, check},
        fetchPolicy: 'no-cache'
    }),
    excelventadetallado: (client, {periodo, downloadPdf, downloadExcel, check}, recursos) => client.query({
        query: gql`query excelventadetallado($periodo: String, $downloadPdf: Boolean, $downloadExcel: Boolean, $check: String){
            excelventadetallado(periodo: $periodo, downloadPdf: $downloadPdf, downloadExcel: $downloadExcel, check: $check){
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {periodo, downloadPdf, downloadExcel, check},
        fetchPolicy: 'no-cache'
    }),
    getLfHojaTrabajo: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFhojaTrabajo($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFhojaTrabajo(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getExcelLfCvd: (client, {periodo, libro, consolidado, incluirCierre, excel_pdf}, recursos) => client.query({
        query: gql`query getExcelLfCvd($periodo: String!, $libro: String!, $consolidado: Boolean, $incluirCierre: Boolean, $excel_pdf: String) {
              excelLfCvd(periodo: $periodo, libro: $libro, consolidado: $consolidado, incluirCierre: $incluirCierre, excel_pdf: $excel_pdf) {
                ${recursos}
              }
            }`,
        context: {timeout: 480000},
        variables: {periodo, libro, consolidado, incluirCierre, excel_pdf},
        fetchPolicy: "no-cache"
    }),
    getLfMayorDetalle: (client, {cuenta, periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLfMayorDetalle($cuenta: String!, $periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean) {
              lFmayorDetalle(cuenta: $cuenta, periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                ${recursos}
              }
            }`,
        variables: {cuenta, periodo, consolidado, incluirCierre},
        fetchPolicy: "no-cache"
    }),
    getLfCuo: (client, {cuo, periodo, consolidado, incluirCierre, moneda}, recursos) => client.query({
        query: gql`query getLfCcuo($cuo: String!, $periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean, $moneda: String) {
              lFcuo(cuo: $cuo, periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre, moneda: $moneda) {
                ${recursos}
              }
            }`,
        variables: {cuo, periodo, consolidado, incluirCierre, moneda},
        fetchPolicy: "no-cache"
    }),
    getLfBgc: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFbgc($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!) {
                lFbgc(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfErn: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLErn($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFern(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfErf: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLErf($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFerf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfLDiario: (client, {periodo}, recursos) => client.query({
        query: gql`query getLFlDiario($periodo: String!){
                lFlDiario(periodo: $periodo) {
                    ${recursos}
                }
            }`,
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLfDiarios: (client, {periodo}, recursos) => client.query({
        query: gql`query getLfDiarios($periodo: String!) {
            lFdiarios(periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {periodo},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    getLfLMayor: (client, {periodo}, recursos) => client.query({
        query: gql`query getLFmayor($periodo: String!) {
                lFmayor(periodo: $periodo) {
                    ${recursos}
                }
            }`,
        variables: {periodo},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    getLfLCaja: (client, {periodo, cuenta_financiera_id, dowExcel, dowPdf}, recursos) => client.query({
        query: gql`query getLFcaja($periodo: String!, $cuenta_financiera_id: String!, $dowExcel: Boolean, $dowPdf: Boolean) {
                lFcaja(periodo: $periodo, cuenta_financiera_id: $cuenta_financiera_id, dowExcel: $dowExcel, dowPdf: $dowPdf) {
                    ${recursos}
                }
            }`,
        context: {timeout: 360000},
        variables: {periodo, cuenta_financiera_id, dowExcel, dowPdf},
        fetchPolicy: 'no-cache'
    }),
    getLfLBancos: (client, {periodo, cuenta_financiera_id, dowExcel, dowPdf}, recursos) => client.query({
        query: gql`query getLFBancos($periodo: String!, $cuenta_financiera_id: String!, $dowExcel: Boolean, $dowPdf: Boolean){
                lFbancos(periodo: $periodo, cuenta_financiera_id: $cuenta_financiera_id, dowExcel: $dowExcel, dowPdf: $dowPdf) {
                    ${recursos}
                }
            }`,
        context: {timeout: 360000},
        variables: {periodo, cuenta_financiera_id, dowExcel, dowPdf},
        fetchPolicy: 'no-cache'
    }),
    cajasBancos: (client, {libro, estado, sDate, sDateHasta, persona, sCuo, excel = false, moneda}, recursos) => client.query({
        query: gql`query cajasBancos($libro: String!, $estado: String!, $sDate: String!, $sDateHasta: String!, $persona: String, $sCuo: String, $excel: Boolean, $moneda: String) {
                cajasBancos(libro: $libro, estado: $estado, sDate: $sDate, sDateHasta: $sDateHasta, persona: $persona, sCuo: $sCuo, excel: $excel, moneda: $moneda) {
                    ${recursos}
                }
            }`,
        context: {timeout: 360000},
        variables: {libro, estado, sDate, sDateHasta, persona, sCuo, excel, moneda},
        fetchPolicy: 'no-cache'
    }),
    rankingProductos: (client, {fecha_Inicio, fecha_Fin, downloadExcel, tipo, downloadPdf}, recursos) => client.query({
        query: gql`query rankingProductos($fecha_Inicio: String!, $fecha_Fin: String!, $downloadExcel: Boolean, $tipo: String, $downloadPdf: Boolean) {
            rankingProductos(fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin,downloadExcel: $downloadExcel, tipo: $tipo, downloadPdf: $downloadPdf){
                ${recursos}
            }
        }`,
        variables: {fecha_Inicio, fecha_Fin, downloadExcel, tipo, downloadPdf},
        fetchPolicy: 'no-cache'
    }),
    excelCajas: (client, {caja_id, fecha_Inicio, fecha_Fin, downloadExcel, isVendedor}, recursos) => client.query({
        query: gql`query reporteCajas($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $downloadExcel: Boolean, $isVendedor: Boolean){
            reporteCajas(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin,downloadExcel: $downloadExcel, isVendedor: $isVendedor){
                ${recursos}
            }
        }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin, downloadExcel, isVendedor},
        fetchPolicy: 'no-cache'
    }),
    excelVendedor: (client, {user_id, fecha_Inicio, fecha_Fin, isVendedor, downloadExcel}, recursos) => client.query({
        query: gql`query reporteVendedor($user_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $downloadExcel: Boolean, $isVendedor: Boolean){
            reporteVendedor(user_id: $user_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin, downloadExcel: $downloadExcel, isVendedor: $isVendedor) {
                ${recursos}
            }
        }`,
        variables: {user_id, fecha_Inicio, fecha_Fin, isVendedor, downloadExcel},
        fetchPolicy: 'no-cache'
    }),
    excelPedidos: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query excelPedidos($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            excelPedidos(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
    getExcelUtilitario: (client, {libro}, recursos) => client.query({
        query: gql`query postExcelUtilitario($libro: String!) {
              excelUtilitario(libro: $libro) {
                ${recursos}
              }
            }`,
        variables: {libro},
        fetchPolicy: "no-cache"
    }),
    excelVoleta: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query excelPedidos($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            excelPedidos(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
    getLfEsf: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFesf($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFesf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfEdr: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFedr($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFedr(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getExcelSmvEdr: (client, {periodo, consolidado, incluirCierre, excel_pdf}, recursos) => client.query({
        query: gql`query getExcelSmvEdr($periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean, $excel_pdf: String) {
              excelSmvEdr(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre, excel_pdf : $excel_pdf) {
                ${recursos}
              }
            }`,
        variables: {periodo, consolidado, incluirCierre, excel_pdf},
        fetchPolicy: "no-cache"
    }),
    getExcelSmvEsf: (client, {periodo, consolidado, incluirCierre, excel_pdf}, recursos) => client.query({
        query: gql`query getExcelSmvEsf($periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean, $excel_pdf: String) {
              excelSmvEsf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre, excel_pdf : $excel_pdf) {
                ${recursos}
              }
            }`,
        variables: {periodo, consolidado, incluirCierre, excel_pdf},
        fetchPolicy: "no-cache"
    }),
    LECompras82: (client, {periodo}, recursos) => client.query({
        query: gql`query LECompras82($periodo: String!){
            LECompras82(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    analizadorAsientos: (client, {periodo, query}, recursos) => client.query({
        query: gql`query ${query}($periodo: String!) {
            ${query}(periodo: $periodo) {
                ${recursos}
            }
        }`,
        context: {timeout: 240000},
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLeCajaBanco: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLeCajaBanco($periodo: String!, $type: String!){
                lECajaBanco(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        context: {timeout: 960000},
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    getLePcge: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLePcge($periodo: String!, $type: String!){
                lEpcge(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    rowLECompras: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeCompras($periodo: String!, $row: String!){
            rowLeCompras(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLECompras82: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeCompras82($periodo: String!, $row: String!){
            rowLeCompras82(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLEVentas: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeVentas($periodo: String!, $row: String!){
            rowLeVentas(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLEDiario: (client, {periodo, fila, type}, recursos) => client.query({
        query: gql`query rowLeDiario($periodo: String!, $row: String!, $type: String!){
            rowLeDiario(periodo: $periodo, row: $row, type: $type){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString(), type},
        fetchPolicy: 'no-cache'
    }),
    excelPlaniMantenimiento: (client, {empresa_id, excel, id_empleado, periodo, send}, recursos) => client.query({
        query: gql`query excelPlaniMantenimiento($empresa_id: String, $excel: String, $id_empleado : String, 
            $periodo: String, $planilla: String, $employee: String, $banco: String, $periodo1: String, $periodo2: String) {
              excelPlaniMantenimiento(empresa_id: $empresa_id, excel: $excel, id_empleado: $id_empleado, 
              periodo: $periodo, planilla: $planilla, employee: $employee, banco: $banco, periodo1: $periodo1, periodo2: $periodo2,) {
                ${recursos}
              }
            }`,
        variables: {
            empresa_id, excel, id_empleado, periodo, planilla: send ? send.planilla : '',
            employee: send ? send.employee : '', banco: send ? send.banco : '', periodo1: send ? send.periodo1 : '',
            periodo2: send ? send.periodo2 : '',
        },
        fetchPolicy: "no-cache"
    }),
    liquidacionImpuestos: (client, {type, accion, periodo}, recursos) => client.query({
        query: gql`query liquidacionImpuestos ($type: String, $accion: String, $periodo: String) {
            liquidacionImpuestos (type: $type, accion: $accion, periodo: $periodo) {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        variables: {type, accion, periodo},
        fetchPolicy: 'no-cache'
    }),
    pdfLiquidacionImpuestos: (client, pdf, recursos) => client.query({
        query: gql`query pdfLiquidacionImpuestos ($pdf: JSON) {
            pdfLiquidacionImpuestos (pdf: $pdf) {
                ${recursos}
            }
        }`,
        context: {timeout: 240000},
        variables: {pdf},
        fetchPolicy: 'no-cache'
    }),
    compensarIrByPerido: (client, {importe, fecha, periodo_compensado}, recursos) => client.query({
        query: gql`query compensarIrByPerido ($importe: Float!, $fecha: String!, $periodo_compensado: String!) {
            compensarIrByPerido (importe: $importe, fecha: $fecha, periodo_compensado: $periodo_compensado) {
                ${recursos}
            }
        }`,
        variables: {importe, fecha, periodo_compensado},
        fetchPolicy: 'no-cache'
    }),
    saldoIr: (client, {periodo}, recursos) => client.query({
        query: gql`query saldoIr ($periodo: String!) {
            saldoIr (periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getRdMayor: (client, {cuenta, f_desde, f_hasta, excel, periodo, isQueryPeriodo,moneda}, recursos) => client.query({
        query: gql`query getRdMayor($cuenta: String!, $f_desde: String, $f_hasta: String, $excel: Boolean, $periodo: String, $moneda: String) {
            rdMayor(cuenta: $cuenta, f_desde: $f_desde, f_hasta: $f_hasta, excel: $excel, periodo: $periodo, moneda: $moneda) {
                ${recursos}
            }
        }`,
        variables: {
            cuenta, f_desde: !isQueryPeriodo ? f_desde : null, f_hasta: !isQueryPeriodo ? f_hasta : null, excel,
            periodo: isQueryPeriodo ? periodo : null,moneda
        },
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    depositoDetracciones: (client, {operaciones}, recursos) => client.query({
        query: gql`query depositoDetracciones($operaciones: JSON!) {
            depositoDetracciones(operaciones: $operaciones) {
                ${recursos}
            }
        }`,
        variables: {operaciones},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    recibosHonorarios: (client, {operaciones}, recursos) => client.query({
        query: gql`query recibosHonorarios($operaciones: JSON!) {
            recibosHonorarios(operaciones: $operaciones) {
                ${recursos}
            }
        }`,
        variables: {operaciones},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    cambiosPatrimonio: (client, {periodo, consolidado}, recursos) => client.query({
        query: gql`query cambiosPatrimonio ($periodo: String!, $consolidado: Boolean!) {
            cambiosPatrimonio (periodo: $periodo, consolidado: $consolidado) {
                ${recursos}
            }
        }`,
        variables: {periodo, consolidado},
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
}

export default ReportesModel
