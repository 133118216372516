import gql from "graphql-tag"

const ControlInterno = {
    // Queries
    getAllOperacionesByEmpresaId: (client, empresa_id) => {
        return client.query({
            query: gql`query getAllOperacionesByEmpresaId($empresa_id: String!){
                getAllOperacionesByEmpresaId(empresa_id: $empresa_id)
            }`,
            variables: {empresa_id},
            fetchPolicy: "no-cache"
        })
    },
    getAllPCGEByEmpresaId: (client, empresa_id) => {
        return client.query({
            query: gql`query getAllPCGEByEmpresaId($empresa_id: String!){
                getAllPCGEByEmpresaId(empresa_id: $empresa_id)
            }`,
            variables: {empresa_id},
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    deleteAllOperacionesByEmpresaId: (client, empresa_id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAllOperacionesByEmpresaId($empresa_id: String!){
                deleteAllOperacionesByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            variables: {empresa_id}
        })
    },
    deleteAllPCGEByEmpresaId: (client, empresa_id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAllPCGEByEmpresaId($empresa_id: String!){
                deleteAllPCGEByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            variables: {empresa_id}
        })
    }
}

export default ControlInterno