import gql from "graphql-tag"
import {isToken} from "../../utils/scripts"

const Empresa = {
    create: (client, {
        ruc, razon, comercial, direccion, departamento, provincia, distrito, ubigeo, estado, condicion, email,
        telefono, documento, logo, dni, representante
    }, recursos) => client.mutate({
        mutation: gql`mutation createEmpresa(
                    $ruc: String!, 
                    $razon_social: String!, 
                    $nombre_comercial: String!, 
                    $direccion: String!, 
                    $departamento: String!, 
                    $provincia: String!, 
                    $distrito: String!, 
                    $ubigeo: String!, 
                    $estado: String!, 
                    $condicion: String!, 
                    $email: String!, 
                    $telefono: String!, 
                    $documento_id: String!,
                    $logo: String!,
                    $dni: String!,
                    $representante: String!
                 ) {
                  createEmpresa(
                    ruc: $ruc, 
                    razon_social: $razon_social, 
                    nombre_comercial: $nombre_comercial, 
                    direccion: $direccion, 
                    departamento: $departamento, 
                    provincia: $provincia, 
                    distrito: $distrito, 
                    ubigeo: $ubigeo, 
                    estado: $estado, 
                    condicion: $condicion, 
                    email: $email, 
                    telefono: $telefono, 
                    documento_id: $documento_id, 
                    logo: $logo,
                    dni: $dni,
                    representante: $representante
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            ruc, razon_social: razon, nombre_comercial: comercial, direccion, departamento, provincia, distrito, ubigeo,
            estado, condicion, email, telefono, documento_id: documento, logo, dni, representante
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    getAll: (client, recursos) => client.query({
        query: gql`query empresas {
              empresas {
                ${recursos}
              }
            }`,
        context: {headers: {isAuth: isToken('GEN001')}},
        fetchPolicy: "no-cache"
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getEmpresa($id: String!){
                empresa(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {
            id
        },
        context: {headers: {isAuth: isToken('GEN001')}},
        fetchPolicy: "no-cache"
    }),
    update: (client, {id, update}, recursos) => client.mutate({
        mutation: gql`mutation updateEmpresa($id: String!, $update: JSON!){
                updateEmpresa(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
        variables: {
            id,
            update
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteEmpresa($id: String!){
                deleteEmpresa(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    getSellerAll: (client, {id_user, id_module}, recursos) => client.query({
        query: gql`query getSellerAll($id_user: String, $id_module: String){
            getSellerAll(id_user: $id_user, id_module: $id_module){
                ${recursos}
            }
        }`,
        variables: {id_user, id_module},
        context: {headers: {isAuth: isToken('GEN001')}},
        fetchPolicy: "no-cache"
    }),
    getSoporte: (client, recursos) => client.query({
        query: gql`query getSoporte {
              getSoporte {
                ${recursos}
              }
            }`,
        context: {headers: {isAuth: isToken('GEN001')}},
        fetchPolicy: "no-cache"
    }),
    updateEmpresas: (client, {
        id, usuario_sol, password_sol, password_pfx, api_fact, soap_url, bearer, soap_envio, soap_tipo, certificado
    }) => client.mutate({
        mutation: gql`mutation updateEmpresas(
            $id: String
            $usuario_sol: String, 
            $password_sol: String, 
            $password_pfx: String, 
            $api_fact: String, 
            $soap_url: String, 
            $bearer: String, 
            $soap_envio: String, 
            $soap_tipo: String,
            $certificado: String
        ){
            updateEmpresas(
                id: $id
                usuario_sol: $usuario_sol, 
                password_sol: $password_sol, 
                password_pfx: $password_pfx, 
                api_fact: $api_fact, 
                soap_url: $soap_url, 
                bearer: $bearer, 
                soap_envio: $soap_envio, 
                soap_tipo: $soap_tipo,
                certificado: $certificado
            ) {
                id
            }
        }`,
        variables: {
            id, usuario_sol, password_sol, password_pfx, api_fact, soap_url, bearer, soap_envio, soap_tipo, certificado
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarEmpresa($id: String!){
                  activarEmpresa(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    getActiva: (client, recursos) => client.query({
        query: gql`query {
                empresaActiva {
                      ${recursos}
                  }
              }`,
        fetchPolicy: 'no-cache',
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    logo: (client, image, recursos) => client.mutate({
        mutation: gql`mutation logoEmpresa($image: Upload!){
                logoEmpresa(image: $image) {
                    ${recursos}
                  }
                }`,
        variables: {
            image
        },
        context: {headers: {isAuth: isToken('GEN001')}},
    }),
    newSelectCompany: (client, user_id, recursos) => client.subscribe({
        query: gql`
        subscription newSelectCompany($user_id: String!) {
          newSelectCompany(user_id: $user_id) {
            ${recursos}
          }
        }`,
        variables: {user_id}
    }),
    permisoByEmpresa: (client, {modulo_id}, recursos) => client.query({
        query: gql`query permisoByEmpresa ($modulo_id: String!) {
            permisoByEmpresa (modulo_id: $modulo_id) {
                ${recursos}        
            }
        }`,
        variables: {modulo_id},
        fetchPolicy: 'no-cache'
    }),
    getUit: (client, recursos) => client.query({
        query: gql`query getUit{
                getUit{
                    ${recursos}
                }
            }`,
        context: {headers: {isAuth: isToken('GEN001')}},
        fetchPolicy: "no-cache"
    }),
    empresaValidateGuia: (client) => client.query({
        query: gql` query empresaValidateGuia{
            empresaValidateGuia{
                data
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query empresasByParam($param: String!){
            empresasByParam(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    credentialsSIRE: (client, {empresa_id, path, body, apis, id_sunat}, recursos) => client.query({
        query: gql`query credentialsSIRE($empresa_id: String!,$path: String!, $body: JSON!, $apis: JSON, $id_sunat: String) {
            credentialsSIRE(empresa_id: $empresa_id, path: $path, body: $body, apis: $apis, id_sunat: $id_sunat) {
                ${recursos}
            }
        }`,
        variables: {empresa_id, path, body, apis, id_sunat},
        fetchPolicy: 'no-cache'
    }),
    depositosDetracciones: (client, {empresa_id, path}, recursos) => client.query({
        query: gql`query depositosDetracciones($empresa_id: String!, $path: String!) {
            depositosDetracciones(empresa_id: $empresa_id, path: $path) {
                ${recursos}
            }
        }`,
        variables: {empresa_id, path},
        fetchPolicy: 'no-cache'
    }),
    constanciaDetraccion: (client, {constancia}, recursos) => client.query({
        query: gql`query constanciaDetraccion($constancia: String!) {
            constanciaDetraccion(constancia: $constancia) {
                ${recursos}
            }
        }`,
        variables: {constancia},
        fetchPolicy: 'no-cache'
    }),
    consultaNPD: (client, {empresa_id, path}, recursos) => client.query({
        query: gql`query consultaNPD($empresa_id: String!, $path: String!) {
            consultaNPD(empresa_id: $empresa_id, path: $path) {
                ${recursos}
            }
        }`,
        variables: {empresa_id, path},
        fetchPolicy: 'no-cache'
    }),
    depositosNPD: (client, {numNPD}, recursos) => client.query({
        query: gql`query depositosNPD($numNPD: String!) {
            depositosNPD(numNPD: $numNPD) {
                ${recursos}
            }
        }`,
        variables: {numNPD},
        fetchPolicy: 'no-cache'
    }),
    declaracionesPagos: (client, {empresa_id, path}, recursos) => client.query({
        query: gql`query declaracionesPagos($empresa_id: String!, $path: String!) {
            declaracionesPagos(empresa_id: $empresa_id, path: $path) {
                ${recursos}
            }
        }`,
        variables: {empresa_id, path},
        fetchPolicy: 'no-cache'
    }),
    detalles_constanciaDeclaracionesPagos: (client, {numeroOrden, codigoEcm, path}, recursos) => client.query({
        query: gql`query detalles_constanciaDeclaracionesPagos($numeroOrden: String!, $codigoEcm: String!, $path: String!) {
            detalles_constanciaDeclaracionesPagos(numeroOrden: $numeroOrden, codigoEcm: $codigoEcm, path: $path) {
                ${recursos}
            }
        }`,
        variables: {numeroOrden, codigoEcm, path},
        fetchPolicy: 'no-cache'
    }),
    tributosDeclaracionesPagos: (client, {numeroOrden}, recursos) => client.query({
        query: gql`query tributosDeclaracionesPagos($numeroOrden: String!) {
            tributosDeclaracionesPagos(numeroOrden: $numeroOrden) {
                ${recursos}
            }
        }`,
        variables: {numeroOrden},
        fetchPolicy: 'no-cache'
    }),
    getByRuc: (client, {ruc}, recursos) => client.query({
        query: gql`query getByRuc($ruc: String!) {
            getByRuc(ruc: $ruc) {
                ${recursos}
            }
        }`,
        variables: {ruc},
        fetchPolicy: 'no-cache'
    }),
    registerCompany: (client, recursos) => client.mutate({
        mutation: gql`mutation {
            registerCompanyApifact {
                ${recursos}
            }
        }`,
    }),
    generatePDF: (client, {data}, recursos) => client.query({
    query: gql`query generatePDF($data: JSON){
                    generatePDF(data: $data){
                        'success, result, message'
                    }
               }`,
    variables: {data},
    fetchPolicy: 'no-cache',
}),
}

export default Empresa
